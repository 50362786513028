<template>
  <div class="tenant-operation-wrapper">
    <title-bar :title="'运营部营收分析'"></title-bar>
    <div class="page-box">
      <operation :showParkingIncome="true"></operation>
    </div>
    <transition name="slide" tag="div">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Operation from "../components/Operation.vue";

export default {
  components: {
    Operation
  },

  data: () => ({
    itemDomList: [
      {
        label: "车场总营收",
        key: "totalIncomeGrowthRate",
        up: "totalIncomeIncreaseNum",
        down: "totalIncomeDecreaseNum",
        flat: "totalIncomeEqNum"
      },
      {
        label: "单车位营收",
        key: "unitPsGrowthRate",
        up: "unitPsIncreaseNum",
        down: "unitPsDecreaseNum",
        flat: "unitPsEqNum"
      },
      {
        label: "单车位预缴收入",
        key: "unitPsPrepayGrowthRate",
        up: "unitPsPrepayIncreaseNum",
        down: "unitPsPrepayDecreaseNum",
        flat: "unitPsPrepayEqNum"
      },
      {
        label: "单车位临停收入",
        key: "tempParkIncomeGrowthRate",
        up: "tempParkIncomeIncreaseNum",
        down: "tempParkIncomeDecreaseNum",
        flat: "tempParkIncomeEqNum"
      }
    ],
    pageReq: {}, // 请求对象

    // --总营收图表组件--
    tenantColumns: [], // 租户列表
    totalRevenueData: {}, // 组件数据

    // --车场营收分析--
    parkingIncomeData: {},

    // --营收分析--
    totalOperationData: {},

    // --月营收分析--
    monthlyOperationData: {},

    // --年同比--
    annualRateData: {},
    annualRateType: 1
  }),

  methods: {
    gotoPage(name, params) {
      this.$router.push({ name, params });
    },

    // -----总营收图表组件-----
    // 页面初始化函数
    async totalRevenueInit() {
      // 获取租户信息
      let { data } = await this.$api.getTenant();

      // 添加 "全部" 选项
      let total = {
        text: "全部"
      };
      data.forEach(item => {
        item.text = item.name;
        if (!total.id) {
          total.id = item.id;
        } else {
          total.id += "," + item.id;
        }
      });

      data.unshift(total);
      this.tenantColumns = data;

      // 初始请求信息为： 租户--全部 时间--现在
      this.pageReq.tenantId = total.id;
      this.pageReq.date = new Date();
      this.pageReq.lastDate = new Date();
    },

    // 请求获取页面数据
    async reqTotalRevenueData({ tenantId, date }) {
      let reqDate = new Date(date);

      let { data } = await this.$api.getTotalRevenueData(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1
      );

      this.totalRevenueData = data;
    },

    // 选择器点击确定回调函数
    totalRevenueConfirm(obj) {
      obj["tenant"] && (this.pageReq.tenantId = obj["tenant"]["id"]);
      obj["date"] && (this.pageReq.date = obj["date"]);
      console.log("总营收图表请求数据 ==>", this.pageReq);

      this.reqTotalRevenueData(this.pageReq);
    },

    // --营收分析--
    // 请求页面数据
    async reqTotalOperationData({ tenantId, date }) {
      let reqDate = new Date(date);

      let { data } = await this.$api.getTotalOperation(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1
      );

      // console.log("--营收分析--请求页面数据 ==>", data);

      this.totalOperationData = data;
    },

    // --车场营收分析--
    // 请求数据
    async reqParkingIncome({ tenantId, date }) {
      let reqDate = new Date(date);
      let { data } = await this.$api.getParkingIncome(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1
      );
      console.log("--车场营收分析--请求页面数据 ==>", data);
      this.parkingIncomeData = data;
    },

    // --月营收对比分析
    // 请求数据
    async reqMonthlyOperationData({ tenantId, date, lastDate }) {
      let reqDate = new Date(date);
      let reqLastDate = new Date(lastDate);

      let { data } = await this.$api.getMonthlyOperationData(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1,
        reqLastDate.getFullYear(),
        reqLastDate.getMonth() + 1
      );
      // console.log(data);
      this.monthlyOperationData = data;
    },

    // 月营收日期选择器点击确定回调函数
    async monthlyOperationConfirm(val) {
      console.log(val);
      val && (this.pageReq.lastDate = val);

      this.reqMonthlyOperationData(this.pageReq);
    },

    async reqAnnualRateDate({ tenantId, date }, type) {
      let reqDate = new Date(date);
      let { data } = await this.$api.getAnnualRate(
        tenantId,
        reqDate.getFullYear(),
        type
      );

      this.annualRateData = data;
      console.log("请求折线图信息 ==>", data);
    },

    AnnualRateConfirm(val) {
      this.annualRateType = val;

      this.reqAnnualRateDate(this.pageReq, this.annualRateType);
    },

    async pageInit() {
      await this.totalRevenueInit();

      this.reqTotalRevenueData(this.pageReq);
      this.reqTotalOperationData(this.pageReq);
      this.reqParkingIncome(this.pageReq);
      this.reqMonthlyOperationData(this.pageReq);
      this.reqAnnualRateDate(this.pageReq, this.annualRateType);
    }
  },

  mounted() {
    this.pageInit();
  }
};
</script>

<style lang="less" scoped>
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}

.tenant-operation-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.page-box {
  flex: 1;
  width: 100%;
  background-color: #f0f2f5;
  overflow: hidden;
  position: relative;
}
</style>
